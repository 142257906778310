
import React, {useEffect, useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import Typed from 'typed.js';

import { useNavigate } from 'react-router-dom'    
import { useTranslation } from 'react-i18next'; 
import AOS from "aos";
import GLightbox from 'glightbox';

import "aos/dist/aos.css";

export default function Dashboard() {  

    const portfolioRef = useRef(null);
    const { t } = useTranslation(); 
    const { i18n } = useTranslation(); 
    const typedRef = useRef(null);
    const [lang, setLang] = useState(() => localStorage.getItem('lang') || "en");
    const [typedInstance, setTypedInstance] = useState(null); 

    const languages = [ 
        { value: "en", label: t("E"), label2: t("English") },
        { value: "zh", label: t("C"), label2: t("Chinese") }, 
    ];

    const handleUpdateLang = (newLang) => {     
        i18n.changeLanguage(newLang); 
        setLang(newLang); 
        localStorage.setItem('lang', newLang);  
    } 
  
    useEffect(() => {
        if (typedRef.current) {
            if (typedInstance) {
                typedInstance.destroy();
            }
            
            const typedStrings = t(`Traveler, Dream Maker, Pet Lover`);
            typedRef.current.setAttribute('data-typed-items', typedStrings);
            const newTypedInstance = new Typed(typedRef.current, {
                strings: typedStrings.split(','),
                loop: true,
                typeSpeed: 100,
                backSpeed: 50,
                backDelay: 2000,
              });
              setTypedInstance(newTypedInstance);
        }
    }, [lang]);

    useEffect(() => { 
        var lightboxInlineIframe = GLightbox({
            selector: '.portfolio-lightbox'
        });
        lightboxInlineIframe.init();

      }, []);
    
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
    };
        
    useEffect(() => {
        if (isActive) {
        document.body.classList.add('mobile-nav-active');
        } else {
        document.body.classList.remove('mobile-nav-active');
        }
    }, [isActive]);

    
    return (
    <>    
        <i
        className={`bi ${isActive ? 'bi-x' : 'bi-list'} mobile-nav-toggle d-lg-none`}
        onClick={handleClick}
        />

        <header id="header" className="d-flex flex-column justify-content-center">
            <nav id="navbar" className="navbar nav-menu">
            <ul>
                <li key="home"><a href="#hero" className="nav-link scrollto active"><i className="bx bx-home"></i> <span>{t("Home")}</span></a></li>
                <li key="about"><a href="#about" className="nav-link scrollto"><i className="bx bx-user"></i> <span>{t("About")}</span></a></li>
                <li key="company"><a href="#company" className="nav-link scrollto"><i className="bx bx-file-blank"></i> <span>{t("Company")}</span></a></li>
                <li key="organizer"><a href="#organizer" className="nav-link scrollto"><i className="bx bx-book-content"></i> <span>{t("Organizer")}</span></a></li>
                <li key="showreel"><a href="#showreel" className="nav-link scrollto"><i className="bx bx-server"></i> <span>{t("Showreel")}</span></a></li> 
              
                {languages.map((language, index) => (
                <li className="nav-lang" key={index} >
                    <a 
                    className={`nav-link ${language.value === lang ? 'active' : ''}`}
                    onClick={ () => handleUpdateLang(language.value) }
                    >
                        {language.label} <span>{t(language.label2)}</span>
                    </a>
                </li> 
                ))} 
            
        
            </ul>
            </nav>
        </header> 
        
        <section id="hero" className="d-flex flex-column justify-content-center">
            <div className="container">
                <h1 data-aos="zoom-in" data-aos-delay="100">Jack Oon 温佳禾</h1>
                <p data-aos="zoom-in" data-aos-delay="250">{t("I'm")} <span ref={typedRef}  className="typed" data-typed-items={t("Traveler, Dream Maker, Pet Lover")}></span></p>
                <div className="contact-info" data-aos="zoom-in" data-aos-delay="400">
                    <div>{t("Email")}: <a href="mailto:oonkahhoe@gmail.com" target="_blank">oonkahhoe@gmail.com</a></div>
                    <div>{t("Tel")}: <a href="tel:+60125814818" target="_blank">+60125814818</a></div>
                </div>
                <div className="social-links" data-aos="zoom-in" data-aos-delay="500"> 
                    <a target="_blank" href="https://www.facebook.com/jackoon85" className="facebook"><i className='bx bxl-meta' ></i></a>
                    <a target="_blank" href="https://www.instagram.com/jackoon85/" className="instagram"><i className="bx bxl-instagram"></i></a> 
                </div>
            </div>
        </section>
        
        <main id="main"> 
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                    <h2>{t("About")}</h2> 
                    </div>

                    <div className="row">
                    <div className="col-lg-4">
                        <img src="assets/img/profile-img.jpeg" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-lg-8 pt-4 pt-lg-0 content"> 
                        <div className="row">
                        <div className="col-lg-12">
                            <p>{t("Hello, I’m Jack Oon with over 18 years of entrepreneurial experience. I focus on transforming innovative ideas into successful businesses, driven by strategic thinking and creativity. I believe that 'The best way to predict the future is to invent it,' and I incorporate this philosophy into my work. I am passionate about identifying market opportunities, solving complex challenges, and building enterprises with lasting impact. Throughout my career, I have successfully led multiple projects and remain committed to driving positive change in the industry。")}</p> 
                        </div> 
                        </div> 
                    </div>
                    </div>

                </div>
            </section> 
            
            <section id="achievement" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                    <h2>{t("Achievement")}</h2> 
                    </div>

                    <div className="row"> 
                    <div className="col-lg-12 pt-4 pt-lg-0 content">  
                        <p>{t("Achievement1")}</p>
                        <p>{t("Achievement2")}</p>
                        <p>{t("Achievement3")}</p>
                        <p>{t("Achievement4")}</p> 
                    </div>
                    </div>

                </div>
            </section>
            
            <section id="company" className="facts">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                    <h2>{t("My Company")}</h2> 
                    </div>

                    <div className="row">

                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                            <img src="assets/img/onearthtravel.jpeg" className="img-fluid" alt=""/>
                            </div> 
                            <h4>Onearth travel & tours sdn bhd</h4>
                            <h4><a href="https://www.facebook.com/travelwithgtravel" target="_blank"><i className='bx bxl-meta' ></i></a></h4> 
                            <ul><li key="A0">{t("CEO")}</li></ul>

                        </div>

                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                            <img src="assets/img/S9S_Solution.png" className="img-fluid" alt=""/>
                            </div>  
                            <h4>S9S Solution Sdn. Bhd.</h4>
                            <h4><a href="https://s9s-solutions.com/" target="_blank"><i className='bx bx-globe'></i></a></h4> 
                            <ul><li key="A1">{t("Director")}</li></ul>

                        </div>
 
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                            <img src="assets/img/fincorp.png" className="img-fluid" alt=""/>
                            </div> 
                            <h4>Fincorp Insurance</h4>
                            <h4><a href="https://fincorpinsurancebroker.com.kh/" target="_blank"><i className='bx bx-globe'></i></a></h4> 
                            <ul><li key="A2">{t("Director")}</li></ul>
                        </div> 
  
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                            <img src="assets/img/agro_capital.jpeg" className="img-fluid" alt="" />
                            </div> 
                            <h4>Agro Capital</h4>
                            <h4><a href="https://agrocapital.asia/" target="_blank"><i className='bx bx-globe'></i></a></h4> 
                            <ul><li key="A3">{t("Director")}</li></ul>
                        </div> 
                        
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                                <img src="assets/img/raito.png" class="img-fluid" alt="" />
                            </div>
                            <h4>Raito Izakaya</h4>
                            <h4>
                                <a href="https://www.instagram.com/raito_izakaya/?hl=en" target="_blank"><i className='bx bxl-instagram'></i></a>&nbsp;
                                <a href="https://www.facebook.com/raito9izakaya/" target="_blank"><i className='bx bxl-meta'></i></a>
                            </h4>
                            <ul><li key="A4a">{t("Share Owner")}</li></ul> 
                        </div>
            
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                                <img src="assets/img/syncone.png" className="img-fluid" alt=""/>
                            </div>
                            <h4>SynCone</h4>
                            <h4><a href="https://syncone.co/" target="_blank"><i className='bx bx-globe'></i></a></h4> 
                            <ul><li key="A4">{t("Board member")}</li></ul>
                        </div> 
                    </div> 
                </div>
            </section>
            
            <section id="patnership" className="patnership">
                <div className="container" data-aos="fade-up"> 
                    <div className="section-title">
                        <h2>{t("The Patnership")}</h2> 
                    </div>
            
                    <div className="row"> 
            
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                                <img src="assets/img/breaks.png" className="img-fluid" alt=""/>
                            </div> 
                            <h4>Beaks</h4>
                            <h4><a href="https://beaks.my/" target="_blank"><i className='bx bx-globe'></i></a></h4> 
                            <ul><li key="A5">{t("Project Manager")}</li></ul>
                        </div> 
                        
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                                <img src="assets/img/mixu.png" className="img-fluid" alt=""/>
                            </div> 
                            <h4>Mi-xAI</h4>
                            <h4><a href="https://Mix-u.asia/" target="_blank"><i className='bx bx-globe'></i></a></h4> 
                            <ul><li key="A5">{t("Project Manager")}</li></ul>
                        </div> 

                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                                <img src="assets/img/mpv.jpg" className="img-fluid" alt=""/>
                            </div> 
                            <h4>MPV World</h4>
                            <h4><a href="https://mpvworld.org" target="_blank"><i className='bx bx-globe'></i></a></h4> 
                            <ul><li key="A5">{t("Strategic Partners")}</li></ul>
                        </div> 

                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                                <img src="assets/img/green_pro.jpg" className="img-fluid" alt=""/>
                            </div> 
                            <h4>GreenPro Capital</h4>
                            <h4><a href="https://greenprocapital.com/" target="_blank"><i className='bx bx-globe'></i></a></h4> 
                            <ul><li key="A5">{t("Strategic Partners")}</li></ul>
                        </div> 

                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                                <img src="assets/img/unity_trust.jpg" className="img-fluid" alt=""/>
                            </div> 
                            <h4>Unity Trust Berhad</h4>
                            <h4><a href="https://www.unitytrust.my/" target="_blank"><i className='bx bx-globe'></i></a></h4> 
                            <ul><li key="A5">{t("Strategic Partners")}</li></ul>
                        </div> 
 
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                                <img src="assets/img/eramas.jpg" className="img-fluid" alt=""/>
                            </div> 
                            <h4>Eramas</h4>
                            <h4><a href="https://eramastrustees.com/" target="_blank"><i className='bx bx-globe'></i></a></h4> 
                            <ul><li key="A5">{t("Strategic Partners")}</li></ul>
                        </div> 

                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                                <img src="assets/img/capacity_global_biotech.jpg" className="img-fluid" alt=""/>
                            </div> 
                            <h4>Capacity Global Biotech</h4> 
                            <ul><li key="A5">{t("Strategic Partners")}</li></ul>
                        </div> 

                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                                <img src="assets/img/missexpo.jpg" className="img-fluid" alt=""/>
                            </div> 
                            <h4>MISSEXPO MANAGEMENT</h4> 
                            <ul><li key="A5">{t("Strategic Partners")}</li></ul>
                        </div> 
                    </div> 
                </div>  
            </section>
            
            <section id="organizer" className="organizer section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>{t("The Organizer")}</h2> 
                    </div>

                    <div className="row"> 
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                            <img src="assets/img/funofearthh.png" className="img-fluid" alt=""/>
                            </div>
                            <h4>玩转地球</h4>
                            <h4>
                                <a href="https://www.instagram.com/fun_of_earth/" target="_blank"><i class="bx bxl-instagram"></i></a>&nbsp;
                                <a href="https://www.facebook.com/funofearthh" target="_blank"><i class='bx bxl-meta' ></i></a>&nbsp;
                                <a href="https://youtube.com/@funofficialchannel4439?si=xOdWxq3_Vlm7hrWM" target="_blank"><i class="bx bxl-youtube"></i></a>
                            </h4> 
                            <ul><li key="A6">{t("Page Owner")}</li></ul>
                        </div>

                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                            <img src="assets/img/ALCC.png" className="img-fluid" alt=""/>
                            </div>
                            <h4>Asia luxury car club</h4> 
                            <h4><a href="https://www.instagram.com/alcc.asia/" target="_blank"><i className="bx bxl-instagram"></i></a></h4>
                            <ul><li key="A7">{t("Vice Chairman & Treasurer")}</li></ul>
                        </div> 

                        <div className="col-lg-3 col-md-6 mt-5 mt-md-4 item-box">
                            <div className="image-wrapper">
                            <img src="assets/img/fgh.png" className="img-fluid" alt=""/>
                            </div>
                            <h4>佛光会</h4>
                            <h4><a href="https://www.blia.org/home" target="_blank"><i className='bx bx-globe'></i></a></h4>
                            <ul>
                            <li key="A8">国际佛光会青年总团部马来西亚总体中央委员</li>
                            <li key="A9">国际佛光会马来西亚协会槟城分会副会长</li>
                            <li key="A10">国际佛光会青年总团部马来西亚槟城分团团长</li>
                            <li key="A11">国际佛光会马来西亚协会救援队</li>
                            </ul>
                        </div> 
                    </div> 
                </div> 
            </section>
            
            <section id="showreel" className="portfolio section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>{t("Showreel")}</h2> 
                    </div>
            
                    <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/reel_01.png" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/reel_01.mp4" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/reel_02.png" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/reel_02.mp4" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/reel_03.png" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/reel_03.mp4" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div> 
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/jack_video.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/jack_video.mp4" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>



                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/jack_video.png" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/jack_video.mp4" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/yuimuomakase.png" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/yuimuomakase.mp4" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/ALCC_video.png" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/ALCC_Puiyi_HQ_v2.mp4" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_010.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_010.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>
            
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0710.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0710.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>
            
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_006.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_006.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0711.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0711.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_009.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_009.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0713.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0713.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_008.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_008.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0709.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0709.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>
            
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0715.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0715.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_004.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_004.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>
            
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0718.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0718.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>
            
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0727.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0727.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_007.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_007.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>
            
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0725.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0725.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_005.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_005.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0716.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0716.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>
            
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_002.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_002.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_003.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_003.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0717.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0717.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                        <img src="assets/img/thumb/IMG_0708.jpg" className="img-fluid" alt=""/>
                        <div className="portfolio-info"> 
                            <div className="portfolio-links">
                            <a href="assets/img/reel/IMG_0708.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a> 
                            </div>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
            </section>
            
        </main>
        
        <footer id="footer">
            <div className="container">
            <h3>Jack Oon 温佳禾</h3>  
            <div className="copyright">
                &copy; Copyright <strong><span>Jack Oon</span></strong>. All Rights Reserved
            </div> 
            </div>
        </footer>
    </>
    );
};